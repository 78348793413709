<template>
  <div class="page">
    <div class="card card-left">
      <div class="font">
        <link
          href="https://cdn.jsdelivr.net/npm/animate.css@3.5.1"
          rel="stylesheet"
          type="text/css"
        />
        <transition
          name="custom-classes-transition"
          enter-active-class="animated tada"
          leave-active-class="animated bounceOutRight"
        >
          <div v-show="ow" class="pic">
            <img src="../assets/imgs/wt56.png" />
          </div>
        </transition>
        <div class="video-wrapper">
          <transition name="bounce-in">
            <video v-show="show1" id="myVideo"></video>
          </transition>
          <div v-show="show">
            <button @click="playNextVideo">Next</button>
            <button @click="playBackVideo">Back</button>
          </div>
        </div>
        <div class="gest">
          <img src="../assets/imgs/wt27.png" />
        </div>
        <div class="gest1">
          <img src="../assets/imgs/wt28.png" />
        </div>
        <div v-show="show2" class="logo">
          <img src="../assets/imgs/logo.png" />
        </div>
      </div>
    </div>
  </div>
</template>
  
  
  <script>
export default {
  props: {
    msg: String,
  },

  data() {
    return {
      currentIndex: 0,
      videoList: [
        "https://home/Res/Test0001.mp4",
        "https://home/Res/Test0002.mp4",
        "https://home/Res/Test0003.mp4",
        "https://home/Res/Test0004.mp4",
        "https://home/Res/Test0005.mp4",
        "https://home/Res/Test0006.mp4",
        "https://home/Res/Test0007.mp4",
        "https://home/Res/Test0008.mp4",
        "https://home/Res/Test0009.mp4",
        "https://home/Res/Test0010.mp4",
        "https://home/Res/Test0011.mp4",
        "https://home/Res/Test0012.mp4",
      ],

      ow: false,
      show: false, //按钮的显示
      show1: true,
      show2: true,
      pageNum: 0,
    };
  },

  mounted() {
    this.init();
    this.getPageInfo();

    console.log("window", window);

    // window.OnGestureRecognizer = this.OnGestureRecognizer;

    // window.chrome.webview.postMessage(
    //   "SetGestureEventHandler=window.OnGestureRecognizer"
    // );
  },

  methods: {
    init() {
      var than = this;
      var video = document.getElementById("myVideo");
      video.addEventListener("ended", function () {
        than.currentIndex++;
        if (than.currentIndex >= than.videoList.length) {
          than.currentIndex = 0;
        }
        video.src = than.videoList[than.currentIndex];
        video.play();
      });
      video.src = this.videoList[this.currentIndex];
      video.play();
    },
    playNextVideo() {
      var video = document.getElementById("myVideo");
      this.show1 = !this.show1;
      this.show2 = !this.show2;
      this.ow = !this.ow;
      setTimeout(() => {
        this.show1 = true;
      }, 2000);
      setTimeout(() => {
        this.show2 = true;
      }, 2000);
      setTimeout(() => {
        this.ow = false;
      }, 1000);
      this.currentIndex++;
      if (this.currentIndex >= this.videoList.length) {
        this.currentIndex = 0;
      }
      video.src = this.videoList[this.currentIndex];
      setTimeout(() => {
        video.play();
      }, 1000);
      console.log(this.currentIndex, "1111");
    },
    playBackVideo() {
      var video = document.getElementById("myVideo");
      this.show1 = !this.show1;
      this.show2 = !this.show2;
      this.ow = !this.ow;
      setTimeout(() => {
        this.show1 = true;
      }, 2000);
      setTimeout(() => {
        this.show2 = true;
      }, 2000);
      setTimeout(() => {
        this.ow = false;
      }, 1000);
      this.currentIndex--;
      console.log(this.currentIndex);
      if (this.currentIndex < 0) {
        this.currentIndex = 11;
      }
      video.src = this.videoList[this.currentIndex];
      setTimeout(() => {
        video.play();
      }, 1000);
      console.log(this.currentIndex, "2222");
    },
    getPageInfo() {
      // 调整z-index的值，让页面顺序显示，默认第一页在上面
      var runPage = document.querySelectorAll(".card");
      console.log("runPage", runPage);
      for (var i = 0, len = runPage.length; i < len; i++) {
        runPage[i].style.zIndex = len - i;
      }

      // 模拟翻书
      // setTimeout(() => {
      //   runPage[0].classList.add('left')
      // }, 1000);

      // this.keyDown()

      document.onkeydown = (e) => {
        //事件对象兼容
        let e1 =
          e || event || window.event || arguments.callee.caller.arguments[0];
      };
    },
    // OnGestureRecognizer(s) {
    //   var runPage = document.querySelectorAll(".card");
    //   console.log("runPage", runPage);
    //   for (var i = 0, len = runPage.length; i < len; i++) {
    //     runPage[i].style.zIndex = len - i;
    //   }

    //   console.log("OnGestureRecognizer:" + s);
    //   this.message = s;
    //   console.log("OnGestureRecognizer:" + s);

    //   if (s === "BROWSER_FORWARD") {
    //     this.playBackVideo();

    //     setTimeout(() => {
    //       this.show1 = true;
    //     }, 2000);
    //     setTimeout(() => {
    //       this.show2 = true;
    //     }, 2000);
    //     setTimeout(() => {
    //       this.ow = false;
    //     }, 1000);
    //   } else if (s === "BROWSER_BACK") {
    //     this.playNextVideo();

    //     setTimeout(() => {
    //       this.show1 = true;
    //     }, 2000);
    //     setTimeout(() => {
    //       this.show2 = true;
    //     }, 2000);
    //     setTimeout(() => {
    //       this.ow = false;
    //     }, 1000);
    //     // console.log("this.pageNum", this.pageNum);
    //   }
    // },
  },
};
</script>
  
  
  <style scoped>
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}

.card {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;

  background-color: #444444;
  transition: transform 1s;
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
  background: url("https://home/ResImg/diwen.png") no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 40px 150px;
}

.left {
  /* width: 400px;
      height: 600px; */
  position: absolute;
  right: 0;
  /* background-color: #b5e8f1; */
  transform: rotateY(-180deg);
  z-index: 100 !important;
}

.right {
  z-index: 100 !important;
}

.card div.font {
  position: absolute;
  width: calc(100% - 300px);
  height: calc(100% - 80px);
  backface-visibility: hidden;
  color: #898484;
  font-size: 35px;
  background: url("https://home/ResImg/biankuan.png") no-repeat;
  background-size: 100% 100%;
}

.card div.font {
  /* padding-right: 180px;
  padding-top: 50px;
  padding-left: 75px; */
  box-shadow: 75px 0px 75px 0px rgba(51, 51, 51, 0.3) inset;
}

.card .font .pag {
  font-size: 25px;
  color: #796643;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.card .font .gest1 {
  width: 6%;
  height: 10%;
  position: absolute;
  top: 48%;
  right: -4%;
}
.card .font .gest {
  width: 6%;
  height: 10%;
  position: absolute;
  top: 48%;
  left: -4%;
}
.card .font .logo {
  width: 10%;
  height: 10%;
  position: absolute;
  bottom: 11%;
  right: 4%;
}

.card .font .pic {
  height: 70%;
  width: 30%;
  position: absolute;
  left: 35%;
  top: 25%;
}
.bounce-in-enter-active {
  /* transition: all 0.3s ease;
    transition-delay: 2s; */
  transition: opacity 1s;
}
.bounce-in-leave-active {
  /* transition: all 0.8s cubic=bezier(1, 0.5, 0.8, 1); */
  transition: opacity 0s;
}
.bounce-in-enter {
  opacity: 0;
}
.bounce-in-enter-to {
  opacity: 1;
}
.bounce-in-leave {
  opacity: 1;
}
.bounce-in-leave-to {
  opacity: 0;
}

video {
  height: 100%;
  width: 85%;
  position: absolute;
  left: 8%;
  top: 10px;
}
</style>